export const ratingData = [
	{
		color: "#F74E24",
		label: "Poor",
		value: 1
	},
	{
		color: "#FF975C",
		label: "Fair",
		value: 2
	},
	{
		color: "#FFD070",
		label: "Average",
		value: 3
	},
	{
		color: "#67E8D1",
		label: "Good",
		value: 4
	},
	{
		color: "#04CE8B",
		label: "Excellent",
		value: 5
	}
];

import React from "react";

const Star = ({ isActive, fill }) => {
	return isActive ? (
		<svg
			width="52"
			height="52"
			viewBox="0 0 52 52"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="52" height="52" rx="14" fill={fill} />
			<path
				d="M24.5734 14.3906C25.0224 13.0086 26.9776 13.0086 27.4266 14.3906L29.2555 20.0193C29.4563 20.6373 30.0322 21.0557 30.682 21.0557H36.6004C38.0535 21.0557 38.6576 22.9152 37.4821 23.7693L32.694 27.248C32.1683 27.6299 31.9483 28.307 32.1491 28.925L33.978 34.5537C34.427 35.9357 32.8453 37.0848 31.6697 36.2307L26.8817 32.752C26.3559 32.3701 25.6441 32.3701 25.1183 32.752L20.3303 36.2307C19.1547 37.0849 17.573 35.9357 18.022 34.5537L19.8509 28.925C20.0517 28.307 19.8317 27.6299 19.306 27.248L14.5179 23.7693C13.3424 22.9151 13.9465 21.0557 15.3996 21.0557H21.318C21.9678 21.0557 22.5437 20.6373 22.7445 20.0193L24.5734 14.3906Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg
			width="52"
			height="52"
			viewBox="0 0 52 52"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="52" height="52" rx="14" fill="#F2F4F5" />
			<path
				d="M25.2392 14.6069C25.4786 13.8698 26.5214 13.8698 26.7608 14.6069L28.5897 20.2356C28.8842 21.142 29.7289 21.7557 30.682 21.7557H36.6004C37.3754 21.7557 37.6976 22.7474 37.0706 23.2029L32.2826 26.6817C31.5115 27.2419 31.1888 28.2349 31.4834 29.1413L33.3122 34.77C33.5517 35.5071 32.7081 36.12 32.0812 35.6644L27.2931 32.1857C26.5221 31.6255 25.4779 31.6255 24.7069 32.1857L19.9188 35.6644C19.2919 36.12 18.4483 35.5071 18.6878 34.77L20.5166 29.1413C20.8112 28.2349 20.4885 27.2419 19.7174 26.6817L14.9294 23.2029C14.3024 22.7474 14.6246 21.7557 15.3996 21.7557H21.318C22.2711 21.7557 23.1158 21.142 23.4103 20.2356L25.2392 14.6069Z"
				stroke="#5D6779"
				stroke-width="1.4"
			/>
		</svg>
	);
};

export default Star;
